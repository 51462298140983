.pswp__button--arrow--left:before {
  background-image: url(../img/arrow-slide-outline--white.svg) !important;
  background-position: center center !important;
  background-size: contain !important;
  background-color: transparent !important;
  width: 0.7em;
  left: math.div($padding, 3) * 2;
}

.pswp__button--arrow--right:before {
  background-image: url(../img/arrow-slide-outline--white--right.svg) !important;
  background-position: center center !important;
  background-size: contain !important;
  background-color: transparent !important;
  width: 0.7em;
  right: math.div($padding, 3) * 2;
}

.pswp__counter {
  color: white;
  font-weight: bold;
}

.pswp__button:not(.pswp__button--arrow--left):not(.pswp__button--arrow--right) {
  // background-image: url(../img/photoswipe-black-default-skin-glow.png);
}


.pswp--visible .pswp__bg {
  opacity: 0.5 !important;
}
