@font-face {
  font-family: 'Almarena';
  src: url('../fonts/AlmarenaNeue-DisplayLight.woff') format('woff'),
       url('../fonts/AlmarenaNeue-DisplayLight.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Almarena';
  src: url('../fonts/AlmarenaNeue-DisplayRegular.woff') format('woff'),
       url('../fonts/AlmarenaNeue-DisplayRegular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Almarena';
  src: url('../fonts/AlmarenaNeue-DisplayMedium.woff') format('woff'),
       url('../fonts/AlmarenaNeue-DisplayMedium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
