.ma-accordion {
  overflow: hidden;
  height: auto;
}

[data-toggle-accordion] {
  &:hover {
    cursor: pointer;
  }
}

[data-toggle-accordion] > span:after {
  content: '';
  width: 0.8em;
  height: 0.8em;
  background-image: url('../img/arrow.svg');
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  margin-left: 0.5em;
  margin-top: 0.2em;
  right: $padding;

  .tab--active & {
    transform: rotate(180deg);
    margin-top: 0.2em;
  }
}
