#homepage {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: white;
}

.hero-header {
  background-color: white;
  padding: $padding;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__logo {
    height: 4em;
    flex-grow: 1;
  }

  &__logo-cim {
    height: 2.5em;
  }

  img {
    height: 100%;
    width: auto;
  }
}


.hero {
  position: relative;
  flex-grow: 1;
  //overflow-y: auto;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
  background-position: left center;
  background-size: cover;
  background-color: $color-color-3;

  &, & * {
    color: white;
  }

  @media only screen and (min-width: 530px) {
    min-height: 300px;
  }

  .ma-slideshow {
    height: 100%;
    margin: 0;

    [data-glide-slideshow] {
      height: 100%;
    }
  }

  .glide__arrow {
    opacity: 0;

    @media only screen and (min-width: 1300px) {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }

  .hero__slideshow-container {
    height: 100%;
  }

  .hero__tagline {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: none;

    &.show {
      display: initial;
    }

    img {
      position: absolute;
      bottom: calc(10% + 20px);
      left: calc(5% + 25px);
      max-width: 60vw;
      max-height: 30vh;
    }

    @media only screen and (min-width: 768px) {
      img {
        width: 650px;
        max-width: 60vw;
      }
    }
  }

  .glide__bg {
    width: 100%;
    height: 100%;
    background-size: cover;
  }
}

.nav-menu {
  flex-shrink: 1;
  z-index: 2;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__current-language {
    text-transform: uppercase;
  }
}

.nav-menu__opt {
  position: relative;
  @extend .h4--heading;

  &:not(:last-child) {
    border-bottom: 1px solid $color-color-3;
  }

  &:not([data-toggle-accordion]) {
    margin: 0 $padding;
  }

  & &:not([data-toggle-accordion]) {
    border-color: $color-color-2-light;
    margin: 0;
    padding: 0 $padding;

    &, & * {
      color: $color-color-2;
    }

    &:nth-child(1) {
      border-top: 1px solid $color-color-2-light;
    }
  }

  .ma-accordion &[data-toggle-accordion] {
    border-bottom: 0;
    border-top: 0;
  }

  & + & {
    padding-top: 0;
  }

  &-text {
    text-decoration: none;
    color: inherit;
    line-height: inherit;
    width: 100%;
    padding: $padding*1.2 0;
    display: block;
    line-height: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &, & * {
      color: $color-color-3;
    }

    a {
      display: block;
      text-decoration: none;
    }
  }

  &-pin {
    display: inline-block;

    .fa-thumbtack {
      height: 1.5em;
      width: 1.5em;
      padding-left: 0.5em;
      margin: 0;
      display: block;
      padding-top: 0.2em;
      opacity: 0.3;

      .pinned & {
        opacity: 1;
      }
    }
  }

  &--flex {
    display: flex;
    justify-content: space-between;

    &:before {
      display: none;
    }

    > * {
      width: auto;
    }
  }
}


.feed-slideshow {
  max-height: 50vh;
  display: block;

  [data-glide-slideshow] {
    background: transparent;
    width: 100%;
    height: auto;
    margin: 0;
  }

  .glide__slide {
    padding: $padding*2;
    max-height: 100%;
  }
}



.strip-color {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: $padding/2;
  background-color: $color-color-1;
}


.footer-logos {
  position: relative;
  padding: $padding;
  padding-top:  $padding*3;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid $color-color-3;

  &__logo {
    height: 2.65em;
    width: auto;
    margin-right: $padding*1.5;
    margin-bottom: $padding*1.5;

    @media only screen and (min-width: 900px) {
      margin-right: $padding*2;
    }

    @media only screen and (min-width: 1200px) {
      height: 3em;
      margin-right: $padding*3;
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    img {
      height: 100%;
      width: auto;
    }
  }
}
