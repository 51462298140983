.page-content {
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
  background-color: $color-color-1-light;
}

.block-text-container {
  padding: $padding * 2 $padding;
  padding-bottom: 0;
  min-height: 100%;
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: 900px) {
    padding-bottom: $padding;
  }

  h1:not(:first-child),
  h2:not(:first-child),
  h3:not(:first-child),
  h4:not(:first-child),
  h5:not(:first-child),
  h6:not(:first-child) {
    margin-top: 1.5em;
  }

  h1:not(:last-child),
  h2:not(:last-child),
  h3:not(:last-child),
  h4:not(:last-child),
  h5:not(:last-child),
  h6:not(:last-child) {
    margin-bottom: 1em;
  }
}

.nav-menu__opt-text.sos-num,
.sos-num {
  position: relative;
  text-decoration: none;
  color: black;
  font-weight: bold;
  padding-right: 0.3em;
  margin-right: 2em;
  width: auto;

  // Pedido pelo Bruno, para esconder/remover
  display: none;

  &:after {
    content: '';
    background-image: url('../img/arrow.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    top: -0.5em;
    right: 0;
    position: absolute;
    width: 0.6em;
    height: 0.6em;
    transform: rotate(223deg);
  }
}

.nav-menu__opt-text.sos-num:after {
  top: 0.5em;
}
