.googlemaps-map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.GMAMP-maps-pin-view {
  div {
    font-size: 0.7em;
    font-weight: 500;
    letter-spacing: -0.05;
  }
}

.yNHHyP-marker-view {
  width: 35px;
}
