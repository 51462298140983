$base-font-size: 17;
$h1-font-size: math.div(36, $base-font-size);
$h2-font-size: math.div(28, $base-font-size);
$h4-font-size: math.div(18, $base-font-size);

$color-white: #d9d9d9;
$color-black: #1d1d1b;


$color-color-1: #9CBBB3;
$color-color-1-light: #9CBBB3cf;
$color-color-2: #6A998E;
$color-color-2-light: #6A998Ecf;
$color-color-3: #397969;
$color-color-3-light: #397969cf;

$padding: 15px;
