.ma-slideshow {
  margin-top: $padding *2;
  margin-bottom: $padding;
}

[data-glide-slideshow] {
  height: calc((100vw - #{$padding}*2) / 3 * 2);

  @media only screen and (min-width: 992px) {
    width: 100%;
    height: calc((992px - #{$padding}*2) / 3 * 2);
  }

  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
}

.glide__slides {
  margin-top: 0;
}

[data-glide-captions] {
  margin-top: math.div($padding, 2);
  font-size: 0.8em;
}


.fullwidth-image {
  margin-top: $padding *2;
  padding-bottom: $padding;
  height: auto;

  img {
    width: 100%;
    height: auto;
  }
}
