.heading {
  position: fixed;
  width: 100%;
  padding: $padding*1.2 $padding;
  z-index: 2;
  top: 0;
  display: flex;
  align-items: center;
  background-color: $color-color-1;
}

.heading + .page-content, %heading-margin {
  margin-top: calc(#{$padding}*1.2*2 + #{$h4-font-size}em);
}

.heading__txt {
  width: calc(100% - #{$padding}*2);
  padding-left: calc( #{$h1-font-size}em * 0.5);
  line-height: 1;
  @extend .h4--heading;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.heading__back-home {
  width: 0.7em;
  height: 0.7em;
  cursor: pointer;

  svg {
    height: 100%;
    width: auto;
    object-fit: contain;
    display: block;
    transform: rotate(90deg);

    line {
      stroke: black;
      stroke-miterlimit: 10;
      stroke-width: 3.7px;
    }
    polygon {
      fill: black;
    }
  }

  &.heading__back-home--light {
    svg {
      line {
        stroke: white;
      }
      polygon {
        fill: white;
      }
    }
  }

  &.heading__back-home--dark {
    svg {
      line {
        stroke: black;
      }
      polygon {
        fill: black;
      }
    }
  }
}

.heading__info i {
  margin: 0;
  font-size: 1rem;
}
