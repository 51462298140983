.loading-icon {
  width: 80px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  svg {
    width: 100%;
    height: auto;
    animation: rotating 4s linear infinite;
    transform-origin: center center;
  }
}



@keyframes rotating {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
