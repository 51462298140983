.point-info {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  pointer-events: none;

  &--open {
    pointer-events: all;
    overflow-y: auto;
  }

  &__heading {
    display: flex;
    margin-bottom: 3em;

    &-titles {
      margin-left: 1em;

      h5 {
        color: $color-color-3;
        margin-bottom: 0.5em !important;
      }

      h2 {
        margin-top: 0 !important;
      }
    }
  }

  &__num {
    height: 3.4em;
    display: inline-block;
    position: relative;
    margin-left: -0.2em;

    img,
    svg {
      width: auto;
      height: 100%;
    }
  }

  &__container {
    padding-top: 7em;
    background-color: rgba(0,0,0,0);
    transition: background-color 0.3s ease-in-out;

    .point-info--open & {
      background-color: rgba(0,0,0,0.1);
    }
  }

  &__close-icon {
    position: fixed;
    top: calc(#{$padding}*1.2*2 + #{$h4-font-size}em + 15px);
    right: 15px;
    width: 2em;
    height: 2em;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    .point-info--open & {
      opacity: 1;
    }

    svg {
      width: 100%;
      height: auto;

      path {
        filter: drop-shadow(1px 1px 1px rgba(0,0,0, 0.2));
      }
    }
  }

  &__content {
    background-color: white;
    height: 100%;
    margin-top: calc(100vh - 7em);
    transition: margin-top 0.3s ease-in-out;

    .point-info--open & {
      margin-top: 0;
    }

    .block-text-container {
      min-height: calc(100vh - 7em - #{$padding}*2 - 1.3em);
    }
  }

  &__paths {
    padding-top: 2em;
    padding-bottom: 3em;
  }

  &__path {
    display: inline-block;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
    border-radius: 0.3em;
    padding: 0.3em 0.6em;
    font-size: 0.8em;
    font-weight: 500;
    text-decoration: none;
  }

  &__details {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  &__detail-item {
    margin-bottom: 0.5em;
  }

  &__notes {
    margin-top: 1.5em;
  }
}
