html {
  height: 100%;
}

body {
  height: calc(100% - (#{$padding}*1.2*2 + #{$h4-font-size}em));
  opacity: 0;

  &.body-ready {
    opacity: 1;
  }
}

.text-underline,
a {
  color: inherit;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.15em;

  &.link--not-underlined {
    text-decoration: none;
  }
}

hr {
  border-style: solid;
  opacity: 0.2;
}
