html {
  font-size: #{$base-font-size}px;
  color: $color-black;
}

* {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.36;
}

p + p {
  padding-top: 1em;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

.h1--heading, .h1--heading *,
h1, h1 * {
  font-size: #{$h1-font-size}rem;
  font-weight: 700;
  line-height: 1.2;
}

.h2--heading, .h2--heading *,
h2, h2 * {
  font-size: #{$h2-font-size}rem;
  font-weight: 600;
  line-height: 1.2;
}

.h4--heading, .h4--heading *,
h4, h4 * {
  font-size: #{$h4-font-size}rem;
  font-weight: normal;
  line-height: 1.2;
}

h5, h5 * {
  font-size: #{$h4-font-size}rem;
  line-height: 1.2;
}

li + li {
  padding-top: 0.5em;
}
