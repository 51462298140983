.icon {
  width: 0.95em;
  height: 0.95em;
  display: inline-block;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.icon--close {
  background-image: url('../img/icon-close.svg');
}
